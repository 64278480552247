import type { CSSProperties, ForwardedRef } from 'react'
import { forwardRef, useMemo } from 'react'
import type { TextProps } from './types'

export const Text = forwardRef(
  (
    {
      align: textAlign,
      as = 'span',
      fontSize = 'md',
      color,
      fontWeight,
      lineHeight,
      wrap: textWrap,
      responsive = false,
      ...props
    }: TextProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const Component = as

    const styles: CSSProperties = useMemo(
      () => ({
        ...(color && { color: `var(--color-${color})` }),
        ...(fontSize && {
          fontSize: `var(--font-${responsive ? `responsive-${fontSize}` : fontSize})`,
        }),
        ...(fontWeight && { fontWeight }),
        ...(lineHeight
          ? { lineHeight }
          : {
              lineHeight: `var(--line-height-${fontSize})`,
            }),
        ...(textAlign && { textAlign }),
        ...(textWrap && { textWrap }),
      }),
      [color, fontSize, fontWeight, lineHeight, textAlign, textWrap],
    )

    return <Component ref={ref as never} style={styles} {...props} />
  },
)

Text.displayName = 'Text'
